import React from "react"
import { Link, graphql, navigate } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import get from 'lodash/get';
import Layout from "../components/layout"
import { ValidateRoute} from '../util/route-helper'
import { config } from "../util/configs"

import SEO from "../components/seo"
import PrimaryLandingPage from "../components/primaryLandingPage/primaryLanding";
import Footer from "../components/landingFooter"
import queryString from 'query-string';
import {isNullUndefined, isEmptyString} from "../util/"


class IndexPage extends React.Component {
  constructor(props){
    super(props);
    this.state = { 
      shouldRender: false
    }
  }

  componentDidMount(){
    let shouldRender = ValidateRoute();

    this.setState({shouldRender});

    let {goTo} = queryString.parse(this.props.location.search || "");
    
    if(isNullUndefined(goTo) || isEmptyString(goTo)){
        navigate("/");
    }else{
        navigate(goTo);
    }
  }

  render() { 
    const {shouldRender} = this.state;

    // if(!shouldRender){
    //   return <React.Fragment/>
    // }

    return (
     
    <div>
        <SEO title="Home" />
         {/* <Header /> */}
         <PrimaryLandingPage/>
         <Footer/>
     </div>
      );
  }
}
 
export default IndexPage;